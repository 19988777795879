import styled, { css } from 'styled-components';

type ContactIconProps = {
    icon: {
        url: string;
    };
};

const ContactIcon = styled.div<ContactIconProps>`
    position: relative;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    ::before {
        content: '';
        z-index: 5;
        mask: url(${({ icon }) => icon.url});
        height: 100%;
        width:  100%;
        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;
        background-color: ${({ theme }) => theme.colors.primary.main};
    }
`;

export default ContactIcon;
