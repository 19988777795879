import { useForm } from 'react-hook-form';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { withPreview } from '../utils/withPreview';
import Box from '../components/Box/Box';
import Button from '../components/Button/Button';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import PageWrapper from '../components/PageWrapper/PageWrapper';
import {
    Body1,
    Head2,
    Head4,
    Subheading1,
} from '../components/Typography/Typography';
import useViewport from '../hooks/useViewport';
import ContactIcon from '../components/ContactIcon/ContactIcon';
import Stack from '../components/Stack/Stack';
import CheckMarkIcon from '../images/icons/check-mark.svg';
import { rem } from 'polished';
import InputSlots, { normalizeValues } from '../slots/InputSlots/InputSlots';
import { submitForm } from '../services/externalForm';
import { track } from '../utils/analytics';
import SectionWrapper from '../components/SectionWrapper/SectionWrapper';

const formWrapperStyles = {
    alignSelf: 'start',
    bg: 'neutral.white',
    borderRadius: 'sm',
    p: rem(32),
    border: 'default',
};

function Contact({ data: { page } }) {
    const { body } = page.data;
    const {
        primary: { heading, subheading, title, subtitle, address, form },
    } = body[0];
    const { country, location, location_icon, phone, phone_icon } =
        address.document.data;
    const { submit_url, cta_text, success_msg, fields, cta_tracking_event } =
        form.document.data;
    const { handleSubmit, register, errors, control, reset, setError } =
        useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { isMobile } = useViewport();

    const onSubmit = (values) => {
        setIsSubmitting(true);

        const normalizedValues = normalizeValues(values);
        submitForm({ url: submit_url, data: normalizedValues })
            .then((res) => {
                setIsSubmitted(true);
                track(cta_tracking_event);
                reset();
            })
            .finally(() => setIsSubmitting(false));
    };

    return (
        <PageWrapper page={page}>
            <Header />
            <main>
                <SectionWrapper backgroundVariant="primary.50">
                    <Box gridColumn={{ _: '1/-1', lg: '2/-2' }}>
                        <Head2 fontWeight={600} textAlign={['start', 'center']}>
                            {heading.text}
                        </Head2>
                        <Body1
                            textAlign={['start', 'center']}
                            marginTop={rem(20)}
                        >
                            {subheading}
                        </Body1>
                        <Box
                            marginTop={rem(64)}
                            display="grid"
                            gridGap={['30px']}
                            gridTemplateColumns={{
                                _: '1fr',
                                sm: '1fr 1fr',
                            }}
                        >
                            <Box display="flex" flexDirection="column">
                                {!isMobile && (
                                    <>
                                        <Head4>{title?.text}</Head4>
                                        <Body1 marginTop="20px">
                                            {subtitle}
                                        </Body1>
                                    </>
                                )}

                                <Subheading1
                                    marginTop={['0px', '48px']}
                                    fontWeight={600}
                                >
                                    {country}
                                </Subheading1>
                                <Box
                                    display="flex"
                                    marginTop="20px"
                                    alignItems="center"
                                    gap="10px"
                                >
                                    <ContactIcon icon={location_icon} />
                                    <Body1>{location}</Body1>
                                </Box>
                                <Box
                                    display="flex"
                                    marginTop="20px"
                                    alignItems="center"
                                    gap="10px"
                                >
                                    <ContactIcon icon={phone_icon} />
                                    <Body1>{phone}</Body1>
                                </Box>
                            </Box>
                            <Stack
                                as="form"
                                onSubmit={handleSubmit(onSubmit)}
                                space={rem(20)}
                                noValidate
                                {...formWrapperStyles}
                            >
                                <InputSlots
                                    inputs={fields}
                                    extendProps={({
                                        key,
                                        is_required,
                                        max_length,
                                        pattern,
                                        label,
                                    }) => ({
                                        ref: register({
                                            required: is_required,
                                            maxLength: max_length,
                                            pattern,
                                        }),
                                        error: errors[key],
                                        control,
                                        label,
                                    })}
                                />
                                <Box py={rem(8)}>
                                    <Button
                                        size="lg"
                                        fullWidth
                                        loading={isSubmitting}
                                    >
                                        {cta_text}
                                    </Button>
                                </Box>
                                {isSubmitted && (
                                    <Box
                                        marginTop="28px"
                                        bg="success.100"
                                        padding="8px 44px"
                                        color="success.1700"
                                        display="flex"
                                        gap="12px"
                                    >
                                        <CheckMarkIcon />
                                        <Body1 color="success.1700">
                                            {success_msg}
                                        </Body1>
                                    </Box>
                                )}
                            </Stack>
                        </Box>
                    </Box>
                </SectionWrapper>
            </main>
            <Footer />
        </PageWrapper>
    );
}

export const query = graphql`
    query CONTACT_QUERY($uid: String!, $lang: String!) {
        page: prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
            _previewable
            lang
            data {
                ...PAGE_META_FRAGMENT
                body {
                    ... on PrismicPageDataBodyContactUsPage {
                        id
                        primary {
                            heading {
                                text
                            }
                            title {
                                text
                            }
                            subheading
                            subtitle
                            success_msg
                            cta_text
                            form {
                                type
                                id
                                document {
                                    ... on PrismicDynamicForm {
                                        id
                                        data {
                                            cta_text
                                            cta_tracking_event
                                            fields {
                                                key
                                                is_required
                                                label
                                                max_length
                                                options
                                                placeholder
                                                type
                                            }
                                            submit_url
                                            success_msg
                                            success_image {
                                                gatsbyImageData(
                                                    height: 305
                                                    layout: CONSTRAINED
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                            address {
                                document {
                                    ... on PrismicAddress {
                                        id
                                        data {
                                            location
                                            phone
                                            country
                                            phone_icon {
                                                alt
                                                url
                                            }
                                            location_icon {
                                                alt
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default withPreview(Contact);
